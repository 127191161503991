
.image-input {
    border: none !important;
    height: 10vh !important;
}

.file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 10px 12px;
    background-color: #eb0a8c;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
    height: 16px;
    margin-right: 4px;
}

.flex-auto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-auto button{
    background: #eb0a8c;
    height: 6vh;
}