.card {
    border: none;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
    height: auto;
    border: 4px solid #EB0A8C;
}

/* .card:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: #E1BEE7;
    transform: scaleY(1);
    transition: all 0.5s;
    transform-origin: bottom;
} */

/* .card:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: #EB0A8C;
    transform: scaleY(1);
    transition: all 0.5s;
    transform-origin: bottom
} */

.card:hover::after {
    transform: scaleY(1);
}
.fonts {
    font-size: 11px;
}

.social-list {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 0;
}

.social-list li {
    padding: 10px;
    color: #EB0A8C;
    font-size: 19px;
}

.buttons button:hover {
    background-color: #eb0a8dd3;
}

.buttons button {
    border: 1px solid #EB0A8C !important;
    background-color: #EB0A8C;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    border-radius: 25px;
    height: 40px;
    width: 160px;
}