.reject {
    background-color: deeppink;
    color: #fff;
    font-size: 100%;
    line-height: 1.5;
    font-family: "Roboto", sans-serif;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reject p {
    color: wheat;
}

.button {
    font-weight: 300;
    color: #fff;
    font-size: 1.2em;
    text-decoration: none;
    border: 1px solid #efefef;
    padding: .5em;
    border-radius: 3px;
}

.button:hover {
    background-color: deeppink;
    color: #fff;
}

p {
    font-size: 2em;
    text-align: center;
    font-weight: 100;
}

h1 {
    text-align: center;
    font-size: 12em;
    font-weight: 100;
}