
.card-title{
    color: #eb0a8c;
}
.card-price{
    color: #eb0a8c;
}
.pricing{
    background: #eb0a8c;
}
section.pricing-section {
    width: 100%;
    height: auto;
    
    .pricing {
        width: 100%;
        height: auto;
        position: relative;
        .pricing-body {
            display: flex;
            flex-direction: column;
            .pricing-body-header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                row-gap: 15px;
                h2 {
                    font-weight: 500;
                }
                .pricing-checkbox {
                    display: flex;
                    column-gap: 10px;
                    align-items: center;
                    span {
                        font-size: 14px;
                        color: #b5b6b8;
                    }
                    > div {
                        width: 45px;
                        background: #eb0a8c;
                        height: 24px;
                        border-radius: 15px;
                        position: relative;
                        cursor: pointer;
                        div {
                            position: absolute;
                            width: 18px;
                            height: 18px;
                            background-color: #fff;
                            top: 50%;
                            left: 3px;
                            transform: translateY(-50%);
                            border-radius: 50%;
                        }
                        &.anually div {
                            left: unset;
                            right: 3px;
                        }
                    }
                }
            }
            .pricing-body-plans {
                > div {
                    display: none;
                    > div {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around;
                        align-items: center;
                        flex-wrap: wrap;

                        .card {
                            background-color: #fff;
                            padding: 25px 0px;
                            border-radius: 8px;
                            width: 320px;
                            position: relative;
                            height: auto;

                            .card-header {
                                display: flex;
                                flex-direction: column;
                                row-gap: 8px;
                                justify-content: center;
                                align-items: center;
                                .card-title {
                                    font-weight: 400;
                                    font-size: 14px;
                                }
                                .card-price {
                                    font-size: 26px;
                                }
                                .card-users {
                                    input {
                                        width: 100%;
                                        background: transparent;
                                        border: 1px solid #eb0a8c;
                                        padding: 10px;
                                        color: #b5b6b8;
                                        text-align: center;
                                        outline: none;
                                    }
                                    span {
                                        font-size: 12px;
                                        color: #bc1e4a;
                                    }
                                }
                            }
                            .card-body {
                                margin-top: 30px;
                                ul {
                                    list-style-type: none;
                                    margin-left: 20px;
                                    display: flex;
                                    flex-direction: column;
                                    row-gap: 8px;
                                    li {
                                        color: #eb0a8c;
                                        position: relative;
                                        font-size: 14px;
                                    
                                        &::before {
                                            content: "•";
                                            color: #eb0a8c;
                                            font-weight: bold;
                                            width: 20px;
                                            margin-left: -18px;
                                            font-size: 25px;
                                            position: absolute;
                                            top: 50%;
                                            transform: translateY(-50%);
                                        }
                                    }
                                }
                            }
                            .card-footer {
                                margin-top: 35px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                button {
                                    border: 1px solid #eb0a8c;
                                    background: transparent;
                                    padding: 10px 25px;
                                    color: #b5b6b8;
                                    cursor: pointer;
                                    border-radius: 25px;
                                }
                            }

                            .card-footer button {
                                background: #eb0a8c;
                                color: #fff;
                            }
                        }
                    }

                    &.active {
                        display: block;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    section.pricing-section {
        width: 100%;
        height: auto;
        
        .pricing {
            .pricing-header {
                width: 60%;
                padding: 20px 50px;
            }
            .pricing-body {
                margin-top: 780px;
                padding-bottom: 50px;
                .pricing-body-plans {
                    > div {
                        > div {
                            flex-direction: column;
                            row-gap: 17px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 500px) {
    section.pricing-section {
        .pricing {
            .pricing-header {
                width: 100%;
                padding: 20px 50px;
                position: unset;
                top: unset;
                left: unset;
                transform: unset;
                p {
                    font-size: 14px;
                }
                h3 {
                    font-size: 18px;
                }
            }
            .pricing-body {
                margin-top: 50px;
                position: unset;
                top: unset;
                left: unset;
                transform: unset;
            }
        }
    }
}